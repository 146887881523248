export function pxToVw(px: number, containerWidth = 1440): string {
  return `${(px / containerWidth) * 100}vw`;
}

export function toPx(val: string | number): string | null {
  return val ? `${val}px` : null;
}

export function pxToPercent(px: number, containerWidth = 980): string {
  return `${(px / containerWidth) * 100}%`;
}
