import * as React from 'react';
import { LogoContainer, Img } from './styles';
import { ScreenAware } from 'components/Common/Placement/Placement';

export interface LogoProps {
  image?: ScreenAware<string>;
  alt: string;
}

const Logo = ({ image, alt }: LogoProps) => {
  if (!image || (!image.desktop && !image.mobile)) {
    return null;
  }

  return (
    <LogoContainer>
      <Img className="Logo">
        {image.desktop && (
          <source media="(min-width: 40em)" srcSet={image.desktop} />
        )}
        {image.mobile && (
          <source media="(max-width: 40em)" srcSet={image.mobile} />
        )}
        <img src={image.desktop || image.mobile} alt={alt} />
      </Img>
    </LogoContainer>
  );
};

export default Logo;
