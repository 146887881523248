import styled from 'styled-components';
import { flexbox, space, typography } from 'styled-utils';

import {
  screenAwareColor,
  ScreenAwareColorProps,
} from 'components/Common/Placement/Placement';

export const Content = styled.div<
  {
    desktopColumn?: boolean;
  } & ScreenAwareColorProps
>`
  display: flex;
  align-items: center;
  ${({ colorMobile, colorDesktop, theme }) =>
    screenAwareColor(
      colorMobile || theme.colors.basic100,
      colorDesktop || theme.colors.basic100
    )};
  ${({ theme }) => typography(theme.typography.default)}
  ${({ desktopColumn }) =>
    flexbox({
      flexDirection: ['column', desktopColumn ? 'column' : 'row'],
    })};
`;

export const Reviews = styled.div<{
  desktopColumn?: boolean;
}>`
  display: flex;
  flex-direction: row;
  ${({ desktopColumn }) => space({ mb: ['6px', desktopColumn ? '6px' : 0] })}
`;

export const ReviewsText = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 12px;
`;

export const Members = styled.div<{
  desktopColumn?: boolean;
}>`
  display: flex;
  flex-direction: row;
  ${({ desktopColumn }) => space({ ml: [0, desktopColumn ? 0 : 32] })}
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-right: 6px;
`;
