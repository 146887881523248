import { Button } from 'components/Common/Button';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  text-align: center;
  border-radius: 2px;
  font-size: ${({ theme }) => theme.fontSizes.default};
  margin-top: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.basic100};
  background-color: ${({ theme }) => theme.colors.blue400};
`;
