import styled from 'styled-components';

import {
  fontSize,
  color,
  textAlign,
  height,
  background,
  BackgroundProps,
  HeightProps,
  FontSizeProps,
  ColorProps,
  TextAlignProps,
} from 'styled-utils';

import { SellPageContainer } from 'components/Common/Layout';
import { standardTagMixin } from 'components/Common/Text';

export const StyledSimpleText = styled.div<HeightProps & BackgroundProps>`
  ${height};
  ${background};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin-left: calc(50% - 50vw + var(--scrollbar) / 2);
  margin-right: calc(50% - 50vw + var(--scrollbar) / 2);

  h2,
  p {
    margin-bottom: 0;
  }
`;

export const StyledSimpleTextContainer = styled(SellPageContainer)`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const StyledSimpleTextHeader = styled.h2<
  FontSizeProps & ColorProps & TextAlignProps
>`
  ${fontSize};
  ${color};
  ${textAlign};
  font-weight: 900;
  margin: 0 0 20px;
  line-height: 1.23;
  ${standardTagMixin};
`;

export const StyledSimpleTextLine = styled.p<
  FontSizeProps & ColorProps & TextAlignProps
>`
  ${fontSize};
  ${color};
  ${textAlign};
  ${standardTagMixin};
`;
