import React from 'react';
import { graphql } from 'gatsby';
import { HeroCarousel } from './HeroCarousel';
import { SanityHeroSlider } from 'graphql-types';

interface Props {
  fields: SanityHeroSlider;
}

export const SanityHeroCarousel = ({ fields }: Props) => {
  return <HeroCarousel {...fields} />;
};

export const query = graphql`
  fragment SanityHeroSlider on SanityHeroSlider {
    _key
    _type
    __typename
    carouselInterval
    autoRotate
    withSlideProgressAnimation
    staticContentSlide
    heroes {
      ...SanitySellPageHeroBlock
    }
  }
`;
