import React, { useRef } from 'react';
import { GatsbyImage } from 'components/Common/GatsbyImage';

import { useContainerDimensions } from 'hooks/useContainerDimensions';

import { calculateStyles } from './Image.helper';
import { ImageProps } from './Image.model';

export const Image: React.FC<ImageProps> = ({
  image,
  hotspot,
  crop,
  className,
  alt,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { width, height } = useContainerDimensions(componentRef);

  if (!image) return null;

  // If no hotspot or crop, we're good to go with regular GatsbyImage
  if (!hotspot && !crop) {
    return (
      <GatsbyImage
        image={image}
        alt={alt || ''}
        className={className}
        style={{ width: '100%', height: '100%' }}
      />
    );
  }

  const targetStyles = calculateStyles({
    container: {
      height,
      width,
    },
    image: {},
    hotspot,
    crop,
  });

  return (
    <div style={targetStyles.container} ref={componentRef}>
      <div aria-hidden="true" style={targetStyles.padding} />
      <GatsbyImage
        image={image}
        alt={alt || ''}
        className={className}
        style={targetStyles.crop}
        imgStyle={targetStyles.image}
      />
    </div>
  );
};
