import { useState, useEffect, RefObject } from 'react';
import { debounce } from 'throttle-debounce';

type Dimension = {
  width: number;
  height: number;
};

export const useContainerDimensions = (
  myRef: RefObject<HTMLElement>,
  defaultDimensions: Dimension | null = null,
  useDebounce = false
) => {
  const getDimensions = (): Dimension => ({
    width: myRef.current?.offsetWidth || defaultDimensions?.width || 0,
    height: myRef.current?.offsetHeight || defaultDimensions?.height || 0,
  });

  const [dimensions, setDimensions] = useState<Dimension>(getDimensions());

  useEffect(() => {
    const handleResize = useDebounce
      ? debounce(300, () => {
          setDimensions(getDimensions());
        })
      : () => {
          setDimensions(getDimensions());
        };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return dimensions;
};
