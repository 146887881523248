import React from 'react';
import { CollectionSlider } from './CollectionSlider';
import { SanityCollectionSliderBlockFragment } from 'graphql-types';
import { isValidForUserType } from 'helpers/Customer';
import { UserType } from 'types';
import { CollectionItem } from './CollectionSlider.model';
import { useUserData } from '@bbnb/openfit-frontend-shared';

type SanityCollectionSliderProps = SanityCollectionSliderBlockFragment;

export const SanityCollectionSlider: React.FC<SanityCollectionSliderProps> = (
  props
) => {
  const { role } = useUserData();

  const isEntitled = role === 'subscriber';

  return (
    <CollectionSlider
      backgroundColor={props.backgroundColor?.hex}
      cardBackgroundColor={props.cardBackgroundColor?.hex}
      cardTextColor={props.cardTextColor?.hex}
      items={
        (props.items
          ?.map((item) => {
            if (
              !item ||
              !isValidForUserType(isEntitled, (item.userType || '') as UserType)
            ) {
              return null;
            }

            return {
              image: item.image,
              title: item.title || '',
              url: item.url || '', // deprecated
              linkObject: item.linkObject,
            };
          })
          .filter((item) => item !== null) as CollectionItem[]) || []
      }
    />
  );
};
