import React, { FC, useContext } from 'react';
import { SanityRatingColoredFragment } from 'graphql-types';
import { ThemeContext } from 'styled-components';
import { graphql } from 'gatsby';

import { StarRating } from 'components/Product/StarRating/StarRating';

import {
  Content,
  Reviews,
  ReviewsText,
  Members,
  Bold,
} from './RatingText.styles';

type Props = {
  rating: SanityRatingColoredFragment;
  desktopColumn?: boolean;
};

export const RatingText: FC<Props> = ({
  rating: {
    rating,
    textColorDesktop,
    textColorMobile,
    starsColorDesktop,
    starsColorMobile,
  },
  desktopColumn,
}) => {
  const theme = useContext(ThemeContext);

  if (!rating) return null;
  return (
    <Content
      colorDesktop={textColorDesktop?.hex}
      colorMobile={textColorMobile?.hex}
      desktopColumn={desktopColumn}
    >
      <Reviews desktopColumn={desktopColumn}>
        {rating.rating && (
          <StarRating
            rating={rating.rating || 0}
            starColorDesktop={starsColorDesktop?.hex || theme.colors.basic100}
            starColorMobile={starsColorMobile?.hex || theme.colors.basic100}
          />
        )}
        <ReviewsText>
          <Bold>{rating?.reviewCount || 0}</Bold> App Store Reviews
        </ReviewsText>
      </Reviews>
      <Members desktopColumn={desktopColumn}>
        <Bold>{rating?.membersCount || 0}</Bold> members and counting
      </Members>
    </Content>
  );
};

export const query = graphql`
  fragment SanityRatingColored on SanityRatingColored {
    rating {
      ...SanityRating
    }
    starsColorMobile {
      hex
    }
    starsColorDesktop {
      hex
    }
    textColorMobile {
      hex
    }
    textColorDesktop {
      hex
    }
  }

  fragment SanityRating on SanityRating {
    reviewCount
    rating
    membersCount
  }
`;
