import React from 'react';
import { useEmailForm } from 'hooks';

import {
  Horizontal,
  HorizontalPlacement,
  ScreenAware,
} from 'components/Common/Placement/Placement';
import { Button } from 'components/Common/Button';
import {
  EmailCollectorForm,
  EmailCollectorContainer,
  EmailInput,
  MessageContainer,
  EmailValidationMessage,
} from './EmailCollector.styles';

interface EmailCollectorProps {
  cta?: {
    copy?: string;
    url?: string;
    fullWidthMobile?: boolean;
  };
  placement: ScreenAware<HorizontalPlacement>;
}

export const EmailCollector: React.FC<EmailCollectorProps> = ({
  cta,
  placement,
}) => {
  const {
    setNeedCheckEmail,
    setEmail,
    handleSubmit,
    emailValidationMessage,
  } = useEmailForm();
  return (
    <EmailCollectorForm
      tabIndex={1} // eslint-disable-line jsx-a11y/tabindex-no-positive
    >
      <EmailCollectorContainer>
        <form onSubmit={handleSubmit} data-test-id="collector-form">
          <EmailInput
            tabIndex={2} // eslint-disable-line jsx-a11y/tabindex-no-positive
            placeholder="Email address"
            onBlur={(): void => {
              setNeedCheckEmail(true);
            }}
            onChange={(e): void => setEmail(e.target.value)}
            error={!!emailValidationMessage}
          />
          <MessageContainer>
            <EmailValidationMessage>
              {emailValidationMessage}
            </EmailValidationMessage>
          </MessageContainer>
          <Horizontal
            placement={placement}
            tabIndex={3} // eslint-disable-line jsx-a11y/tabindex-no-positive
          >
            <Button
              variant={!emailValidationMessage ? 'primary' : 'disabled'}
              width={['193px', '320px']}
              fontSize={['15px', '18px']}
              lineHeight={['inherit', 'inherit']}
              type="submit"
              as="button"
              showPointer
            >
              {cta?.copy}
            </Button>
          </Horizontal>
        </form>
      </EmailCollectorContainer>
    </EmailCollectorForm>
  );
};
