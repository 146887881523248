import { graphql } from 'gatsby';
import {
  LinkObjectFragment,
  SanityLadderBundlePage,
  SanityLadderCollectionPage,
  SanityLadderLandingPage,
  SanityLadderProductPage,
  SanityLinkObject,
  SanityOpenfitBundlePage,
  SanityOpenfitCollectionPage,
  SanityOpenfitLandingPage,
  SanityOpenfitProductPage,
  SanitySellPage,
} from 'graphql-types';

export const getUrl = (
  linkObject: SanityLinkObject | LinkObjectFragment | null | undefined,
  fallbackUrl: string | undefined
) => {
  if (!linkObject) return fallbackUrl;

  if (!linkObject.page) {
    if (linkObject.url) return linkObject.url;
    console.log('Fallback url used:', linkObject);
    return fallbackUrl;
  }

  switch (linkObject.page._type) {
    case 'ladderHomePage':
      return '/';
    case 'openfitCollectionPage':
    case 'ladderCollectionPage':
      return `/collections/${
        (linkObject.page as
          | SanityOpenfitCollectionPage
          | SanityLadderCollectionPage)?.slug?.current
      }`;
    case 'openfitProductPage':
    case 'openfitBundlePage':
    case 'ladderProductPage':
    case 'ladderBundlePage':
      return `/products/${
        (linkObject.page as
          | SanityOpenfitProductPage
          | SanityOpenfitBundlePage
          | SanityLadderProductPage
          | SanityLadderBundlePage)?.slug?.current
      }`;
    case 'sellPage':
      return `/plans/${(linkObject.page as SanitySellPage)?.slug?.current}`;
    case 'openfitLandingPage':
    case 'ladderLandingPage':
      return `/pages/${
        (linkObject.page as SanityOpenfitLandingPage | SanityLadderLandingPage)
          ?.slug?.current
      }`;
  }

  console.log('Fallback url used:', linkObject);
  return fallbackUrl;
};

export const query = graphql`
  fragment Color on SanityColor {
    hex
  }

  fragment LinkObject on SanityLinkObject {
    url
    page {
      __typename
      ... on SanitySellPage {
        _type
        slug {
          current
        }
      }
      ... on SanityOpenfitProductPage {
        _type
        slug {
          current
        }
      }
      ... on SanityOpenfitLandingPage {
        _type
        slug {
          current
        }
      }
      ... on SanityOpenfitCollectionPage {
        _type
        slug {
          current
        }
      }
      ... on SanityOpenfitBundlePage {
        _type
        slug {
          current
        }
      }
      ... on SanityLadderProductPage {
        _type
        slug {
          current
        }
      }
      ... on SanityLadderLandingPage {
        _type
        slug {
          current
        }
      }
      ... on SanityLadderCollectionPage {
        _type
        slug {
          current
        }
      }
      ... on SanityLadderBundlePage {
        _type
        slug {
          current
        }
      }
      ... on SanityLadderArticlePage {
        _type
        slug {
          current
        }
      }
      ... on SanityLadderHomePage {
        _type
      }
    }
  }
`;
