import React from 'react';

import { logEvent, awaitClick } from 'helpers/Amplitude';
import { getIcid } from '@bbnb/openfit-frontend-shared';

import { PDPPageContainer } from 'components/Pdp';

import { Image } from 'components/Image/Image';
import { getGradient } from 'components/Common/Background';

import { ImageBlockProps, ImageBlockItem } from './ImageBlock.model';
import {
  Wrapper,
  Row,
  ItemSpacer,
  Item,
  SmallImage,
  ItemContent,
  Top,
  TopTextWrapper,
  TopText,
  TopTextTitle,
  Bottom,
  Title,
  Text,
  CTA,
} from './ImageBlock.styles';
import { StandardRichText } from 'components/StandardRichText/StandardRichText';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { getUrl } from 'helpers/LinkObject';

const RowItem: React.FC<{
  item: ImageBlockItem;
}> = ({ item }) => {
  const itemImage = getSimplifiedImageData(item.image?.image);

  return (
    <Item>
      {itemImage && (
        <Image
          alt={item.image?.alt}
          image={itemImage}
          crop={item.image?.crop}
          hotspot={item.image?.hotspot}
        />
      )}
      <ItemContent>
        <Top>
          <TopTextWrapper>
            <TopText color={item.imageTextColor}>
              <TopTextTitle>{item.imageTitle}</TopTextTitle>
              <div>{item.imageSubtitle}</div>
            </TopText>
          </TopTextWrapper>
        </Top>
        <Bottom>
          <Title color={item.titleColor}>{item.title}</Title>
          <Text>{item.text && <StandardRichText fields={item.text} />}</Text>
          {item.ctaText && (
            <CTA
              variant="primary"
              href={getUrl(item.ctaLinkObject, item.ctaUrl)}
              onClick={awaitClick((e) => {
                const icid = getIcid();
                return logEvent('CTA Click', {
                  key: e.currentTarget.href,
                  ...(icid && { icid }),
                });
              })}
            >
              {item.ctaText}
            </CTA>
          )}
        </Bottom>
      </ItemContent>
    </Item>
  );
};

const RowItemSmallImage: React.FC<{
  item: ImageBlockItem;
}> = ({ item }) => {
  const background =
    (item.bgGradient && getGradient(item.bgGradient)) || item.bgColor;

  const itemImage = getSimplifiedImageData(item.image?.image);

  return (
    <Item background={background}>
      <ItemContent>
        <Top>
          {itemImage && (
            <SmallImage
              image={itemImage}
              imgStyle={{ objectFit: 'contain' }}
              alt={item.image?.alt || ''}
            />
          )}
        </Top>
        <Bottom>
          <Title color={item.titleColor}>{item.title}</Title>
          {item.text && (
            <Text>
              <StandardRichText fields={item.text} />
            </Text>
          )}
          <CTA variant="primary" href={item.ctaUrl}>
            {item.ctaText}
          </CTA>
        </Bottom>
      </ItemContent>
    </Item>
  );
};

export const ImageBlock: React.FC<ImageBlockProps> = ({ bgColor, rows }) => (
  <Wrapper background={bgColor}>
    <PDPPageContainer>
      {rows.map((row, rowIndex) => (
        <Row key={rowIndex}>
          {row.items.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
              {itemIndex === 1 && <ItemSpacer />}
              {item.type === 'imageBlockItem' && <RowItem item={item} />}
              {item.type === 'imageBlockItemSmallImage' && (
                <RowItemSmallImage item={item} />
              )}
            </React.Fragment>
          ))}
        </Row>
      ))}
    </PDPPageContainer>
  </Wrapper>
);
