import styled from 'styled-components';
import {
  background,
  flexbox,
  BackgroundProps,
  space,
  SpaceProps,
  FlexboxProps,
  size,
  SizeProps,
} from 'styled-system';
import { maxWidth, width } from 'styled-utils';

interface ContainerProps
  extends BackgroundProps,
    SpaceProps,
    FlexboxProps,
    SizeProps {
  section?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  ${background};
  ${flexbox};
  ${space};
  ${size};
`;

export const SellPageContainer = styled.div`
  ${space({
    mx: [0, 'auto'],
    pl: ['20px', 0],
    pr: ['20px', 0],
  })};
  ${maxWidth({ maxWidth: ['auto', '728px', '980px'] })};
  ${width({ width: ['100%'] })};
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.container};
  width: 100%;
`;
