import styled from 'styled-components';
import { color, ColorProps, space } from 'styled-utils';
import { device } from 'theme';
import { GatsbyImage } from 'components/Common/GatsbyImage';

export const Container = styled.div<ColorProps>`
  ${color};
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled.div`
  ${space({ py: ['20px', '35px'], px: ['8px', '16px'] })};
  display: table;
  margin: 0 auto;
`;

export const CollectionItemText = styled.p<ColorProps>`
  ${color};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 12px;
  line-height: 16px;
  padding: 0 10px;
  margin: 0;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
`;

export const CollectionItemContainer = styled.a<ColorProps>`
  ${color};
  display: block;
  width: 140px;
  height: 132px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 100ms ease-in-out;
  border: 0;
  text-decoration: none;
  position: relative;

  @media (min-width: ${device.medium.width}) {
    &:hover {
      transform: scale(1.15);
      z-index: 2;
    }
  }
`;

export const CollectionItemWrapper = styled.div`
  ${space({ px: ['4px', '8px'] })};
  display: table-cell;
`;

export const CollectionItemImage = styled(GatsbyImage)`
  margin-top: -17px;
  display: inline-block;
`;
