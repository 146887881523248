import * as React from 'react';
import {
  StyledSimpleText,
  StyledSimpleTextContainer,
  StyledSimpleTextHeader,
  StyledSimpleTextLine,
} from './styles';

import { SimpleTextProps } from './model';

type AlignType = 'center' | 'left' | 'right';

export function getAlign(align?: string): AlignType {
  return align ? (align as AlignType) : 'center';
}

const SimpleText: React.FunctionComponent<SimpleTextProps> = (
  props: SimpleTextProps
): React.ReactElement => {
  const {
    backgroundColor,
    desktopBlockBackgroundHeight,
    desktopMainTextLine1Size,
    desktopMainTextLine2Size,
    desktopMainTextLocation,
    mainTextColor,
    mainTextLine1,
    mainTextLine2,
    mobileBlockBackgroundHeight,
    mobileMainTextLine1Size,
    mobileMainTextLine2Size,
    mobileMainTextLocation,
  } = props;

  return (
    <StyledSimpleText
      className="SimpleText"
      background={backgroundColor}
      height={[
        `${mobileBlockBackgroundHeight}px`,
        `${desktopBlockBackgroundHeight}px`,
      ]}
    >
      <StyledSimpleTextContainer className="SimpleTextInner">
        <StyledSimpleTextHeader
          className="Title"
          color={mainTextColor}
          fontSize={[
            `${mobileMainTextLine1Size}px`,
            `${desktopMainTextLine1Size}px`,
          ]}
          textAlign={[
            getAlign(mobileMainTextLocation),
            getAlign(desktopMainTextLocation),
          ]}
          dangerouslySetInnerHTML={{ __html: mainTextLine1 || '' }}
        />
        <StyledSimpleTextLine
          className="Text"
          color={mainTextColor}
          fontSize={[
            `${mobileMainTextLine2Size}px`,
            `${desktopMainTextLine2Size}px`,
          ]}
          textAlign={[
            getAlign(mobileMainTextLocation),
            getAlign(desktopMainTextLocation),
          ]}
          dangerouslySetInnerHTML={{ __html: mainTextLine2 || '' }}
        />
      </StyledSimpleTextContainer>
    </StyledSimpleText>
  );
};

export default SimpleText;
