import React, { FunctionComponent, useRef } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import {
  SliderContainer,
  AnimatedDotContainer,
  Button,
  AnimatedButton,
  NonAnimatedButtonDone,
  NonAnimatedButton,
  PauseButtonContainer,
  PauseButton,
  PlayButton,
} from '../Slider.styles';
import { SliderProps } from '../model';

export const SliderWithProgressAnimation: FunctionComponent<SliderProps> = ({
  children,
  handleAfterSlide,
  interval,
  autoRotate,
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [pause, setPause] = React.useState(false);
  // Doc: https://keen-slider.io/api/#api
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    afterChange: handleAfterSlide,
    loop: true,
  });
  const timerRef = useRef<undefined | ReturnType<typeof Number>>();

  const prepareNextSlide = () => {
    if (!autoRotate || pause) {
      return;
    }
    clearInterval(timerRef.current);
    timerRef.current = window.setInterval(() => {
      if (slider) {
        slider.next();
      }
    }, interval);
  };

  React.useEffect(() => {
    if (autoRotate && !pause) {
      prepareNextSlide();
      return () => {
        clearInterval(timerRef.current);
      };
    }
    return undefined;
  }, [slider, pause]);

  const togglePause = () => {
    setPause(!pause);
  };

  return (
    <SliderContainer>
      <div
        className="keen-slider"
        ref={ref}
        style={{ visibility: slider ? 'visible' : 'hidden' }}
      >
        {children.map((slide, idx) => (
          <div key={idx} className="keen-slider__slide">
            {slide}
          </div>
        ))}
      </div>
      {slider && (
        <AnimatedDotContainer>
          {[...Array(slider.details().size).keys()].map(
            (slideIndex): React.ReactElement => {
              const isCurrent = slideIndex === currentSlide;
              const ButtonInner = isCurrent
                ? AnimatedButton
                : currentSlide > slideIndex
                ? NonAnimatedButtonDone
                : NonAnimatedButton;

              return (
                <Button
                  data-test-id="dot"
                  key={slideIndex}
                  onClick={(): void => {
                    slider.moveToSlide(slideIndex);
                    prepareNextSlide();
                  }}
                  isActive={slideIndex === currentSlide}
                >
                  <ButtonInner
                    interval={autoRotate ? interval : 0}
                    pause={pause}
                  />
                </Button>
              );
            }
          )}
        </AnimatedDotContainer>
      )}
      {autoRotate && interval && (
        <PauseButtonContainer>
          <button onClick={togglePause}>
            {pause ? <PlayButton /> : <PauseButton />}
          </button>
        </PauseButtonContainer>
      )}
    </SliderContainer>
  );
};
