import React from 'react';
import {
  SanityImageBlock,
  SanityImageBlockItem,
  SanityImageBlockItemSmallImage,
} from 'graphql-types';

import { GradientBackgroundColor } from 'components/Common/Background';
import { ImageBlock } from './ImageBlock';
import { ImageBlockRow, ImageBlockItem } from './ImageBlock.model';
import { isValidForUserType } from 'helpers/Customer';
import { UserType } from 'types';
import { useUserData } from '@bbnb/openfit-frontend-shared';

interface Props {
  fields: SanityImageBlock;
}

export const ImageBlockSanity = ({ fields }: Props) => {
  const { role } = useUserData();
  const isEntitled = role === 'subscriber';

  return (
    <ImageBlock
      bgColor={fields.backgroundColor?.hex}
      rows={
        fields.rows?.map(
          (row): ImageBlockRow => ({
            items:
              (row?.items
                ?.map((item): ImageBlockItem | null => {
                  if (
                    !item ||
                    !isValidForUserType(
                      isEntitled,
                      (item.userType || '') as UserType
                    )
                  ) {
                    return null;
                  }

                  return {
                    type: item._type || 'imageBlock',
                    bgColor: (item as SanityImageBlockItemSmallImage)?.bgColor
                      ?.hex,
                    bgGradient: (item as SanityImageBlockItemSmallImage)
                      .bgGradient as GradientBackgroundColor,
                    title: item.title || '',
                    text: item.text,
                    titleColor: item.titleColor?.hex,
                    image: {
                      hotspot: (item as SanityImageBlockItem).image?.hotspot,
                      crop: (item as SanityImageBlockItem).image?.crop,
                      image:
                        (item as SanityImageBlockItem).image ||
                        (item as SanityImageBlockItemSmallImage).image?.image,
                      alt:
                        (item as SanityImageBlockItem).alt ||
                        (item as SanityImageBlockItemSmallImage).image?.alt,
                    },
                    imageTitle: (item as SanityImageBlockItem).imageTitle,
                    imageSubtitle: (item as SanityImageBlockItem).imageSubtitle,
                    imageTextColor: (item as SanityImageBlockItem)
                      .imageTextColor?.hex,
                    ctaText: item?.ctaText,
                    ctaUrl: item?.ctaUrl, // deprecated
                    ctaLinkObject: item?.ctaLinkObject,
                  };
                })
                .filter((item) => item !== null) as ImageBlockItem[]) || [],
          })
        ) || []
      }
    />
  );
};
