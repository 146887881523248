import styled from 'styled-components';

export const EmailCollectorForm = styled.form``;

export const EmailCollectorContainer = styled.div`
  position: relative;
  max-width: 320px;
`;

export const EmailInput = styled.input<{
  error: boolean;
}>`
  color: ${({ theme }) => theme.colors.gray5};
  font-size: 15px;
  line-height: 22px;
  border-radius: 26px;
  min-width: 320px;
  height: 52px;
  padding-left: 24px;
  border: 1px solid ${({ error, theme }) => error && theme.colors.yellow400};
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 15px;
    line-height: 22px;
  }
`;

export const MessageContainer = styled.div`
  height: 18px;
`;

export const EmailValidationMessage = styled.span`
  position: absolute;
  left: 26px;
  top: 50px;
  color: ${({ theme }) => theme.colors.yellow400};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 13px;
  line-height: 22px;
`;
