import React from 'react';
import {
  SanityHero,
  HeroContent,
  mapSanityBlockToHeroContentProps,
} from 'components/Hero';
import { Slider } from 'components/Common/Slider/Slider';
import { Maybe, SanitySellPageHeroBlock } from 'graphql-types';
import { StaticBody, StaticContent, StaticSlider } from './HeroCarousel.styles';

export interface Props {
  heroes?: Maybe<SanitySellPageHeroBlock>[];
  carouselInterval?: number;
  autoRotate?: boolean;
  withSlideProgressAnimation?: boolean;
  staticContentSlide?: number;
}

export const HeroCarousel = ({
  heroes,
  carouselInterval = 1,
  autoRotate,
  withSlideProgressAnimation,
  staticContentSlide,
}: Props): React.ReactElement | null => {
  const items = (heroes || []).filter(Boolean);
  if (!items.length || !items[0]) return null;

  if (items.length === 1) {
    return <SanityHero fields={items[0]} />;
  }

  const staticContent = staticContentSlide
    ? items[staticContentSlide - 1]
    : null;

  const HeroSlider = () => (
    <Slider
      interval={carouselInterval * 1000}
      autoRotate={autoRotate}
      withSlideProgressAnimation={withSlideProgressAnimation}
    >
      {items?.map(
        (hero: SanitySellPageHeroBlock, i: number): React.ReactElement => {
          return (
            <SanityHero
              fields={hero}
              key={i}
              hideContent={Boolean(staticContent)}
            />
          );
        }
      )}
    </Slider>
  );

  if (staticContent) {
    return (
      <StaticContent>
        <StaticBody>
          <HeroContent {...mapSanityBlockToHeroContentProps(staticContent)} />
        </StaticBody>
        <StaticSlider>
          <HeroSlider />
        </StaticSlider>
      </StaticContent>
    );
  }

  return <HeroSlider />;
};
