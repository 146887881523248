import styled from 'styled-components';
import { space, maxWidth } from 'styled-utils';

export const LogoContainer = styled.div`
  ${space({ pb: ['36px', '36px'] })};
`;

export const Img = styled.picture`
  ${maxWidth({ maxWidth: ['300px', '400px'] })};
`;
