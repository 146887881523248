import styled, { css } from 'styled-components';
import { H1, standardTagMixin } from 'components/Common/Text';
import {
  display,
  height,
  HeightProps,
  justifyContent,
  maxWidth,
  MaxWidthProps,
  space,
  SpaceProps,
  lineHeight,
  fontSize,
  textAlign,
  TextAlignProps,
} from 'styled-utils';
import { isLadder } from 'helpers/Ladder';

export const Container = styled.div<SpaceProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${space};
`;

export const Content = styled.div<SpaceProps & MaxWidthProps & HeightProps>`
  ${space};
  ${maxWidth};
  ${height}
`;

export const HeaderWrapper = styled.div<{ large?: boolean }>`
  ${!isLadder() && space({ pt: ['75px', '0px'] })};
  && h1 {
    ${({ large }) => css`
      ${fontSize({ fontSize: large ? [40, 70] : [24, 32] })}
      ${lineHeight({ lineHeight: large ? [1, 1] : ['1.16', '1.13'] })}
    `};
  }
`;

export const Header = styled(H1)<TextAlignProps>`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  ${space({ pb: ['5px', '0px'] })};
  ${textAlign};
  sup {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
  ${standardTagMixin};
`;

interface TextProps {
  desktopOnly?: boolean;
  mobileOnly?: boolean;
}

export const Text = styled.div<TextProps & TextAlignProps>`
  ${lineHeight({ lineHeight: ['1.23', '1.33'] })};
  ${fontSize({ fontSize: ['14px', '14px', '18px'] })};
  ${textAlign};
  margin-top: -10px;
  b {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  .textSmall strong {
    font-weight: ${({ theme }) => theme.fontWeights.black};
  }
  ${(props) =>
    props.desktopOnly &&
    css`
      ${display({ display: ['none', 'block'] })};
    `};
  ${(props) =>
    props.mobileOnly &&
    css`
      ${display({ display: ['block', 'none'] })};
    `};
`;

export const DesktopButtonBottom = styled.div`
  flex-direction: column;
  ${height};
  ${display({ display: ['block', 'flex'] })};
  ${justifyContent({ justifyContent: ['space-between'] })};
`;

export const RatingWrapper = styled.div`
  ${space({ mt: ['20px', '40px'] })}
`;
