import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import utils from 'utils';
import { getLoginURL } from 'utils/url';
import { logEvent, awaitClick } from 'helpers/Amplitude';
import { getIcid } from '@bbnb/openfit-frontend-shared';

import Placement, {
  Horizontal,
  HorizontalPlacement,
  VerticalPlacement,
} from 'components/Common/Placement/Placement';
import { Button } from 'components/Common/Button';
import { ButtonVariant } from 'components/Common/Button/model';
import { SellPageContainer } from 'components/Common/Layout';
import Logo from 'components/Common/Logo/Logo';
import { SanityRichText } from 'components/Common/SanityRichText';
import { getAlign } from 'components/SimpleText';
import { EmailCollector } from 'components/EmailCollector/EmailCollector';
import { Violator } from 'components/Common/Violator/Violator';
import { RatingText } from 'components/RatingText';

import {
  Container,
  HeaderWrapper,
  Header,
  Content,
  Text,
  RatingWrapper,
} from './Hero.styles';
import { HeroContentProps } from './Hero.model';
import { getUrl } from 'helpers/LinkObject';

export const HeroContent: React.FC<HeroContentProps> = ({
  contentHeight = {
    mobile: 0,
    desktop: 500,
  },
  emailCollector,
  copyPositionVertical = {
    mobile: VerticalPlacement.MIDDLE,
    desktop: VerticalPlacement.MIDDLE,
  },
  copyPositionHorizontal = {
    desktop: HorizontalPlacement.CENTER,
    mobile: HorizontalPlacement.CENTER,
  },
  ctaPosition = {
    desktop: HorizontalPlacement.LEFT,
    mobile: HorizontalPlacement.LEFT,
  },
  logo,
  logoPosition = {
    desktop: HorizontalPlacement.LEFT,
    mobile: HorizontalPlacement.LEFT,
  },
  pinLogoToTop,
  preHeader,
  header = {
    copy: '',
  },
  copy,
  copyAlign = {
    desktop: HorizontalPlacement.LEFT,
    mobile: HorizontalPlacement.CENTER,
  },
  mobileDescriptionBottom,
  paddingBottom = {
    mobile: 0,
    desktop: 0,
  },
  paddingTop = {
    mobile: 0,
    desktop: 0,
  },
  copyWidth = {
    desktop: 450,
    mobile: 300,
  },
  cta = {
    copy: '',
    url: '#plans', // deprecated
    linkObject: {},
    ctaVariant: 'primary',
  },
  rating,
  violators,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <SellPageContainer>
      <Container
        pt={[paddingTop.mobile || null, paddingTop.desktop || null]}
        pb={[paddingBottom.mobile || null, paddingBottom.desktop || null]}
        paddingX="30px"
      >
        {violators?.map((violator, index) => (
          <Violator key={`${index}`} {...violator} />
        ))}
        {pinLogoToTop && (
          <Horizontal placement={logoPosition}>
            <Logo image={logo} alt="Logo" />
          </Horizontal>
        )}
        <Placement
          contentPlacement={{
            vertical: {
              desktop: copyPositionVertical.desktop || VerticalPlacement.MIDDLE,
              mobile: copyPositionVertical.mobile || VerticalPlacement.MIDDLE,
            },
            horizontal: {
              desktop:
                copyPositionHorizontal.desktop || HorizontalPlacement.CENTER,
              mobile:
                copyPositionHorizontal.mobile || HorizontalPlacement.CENTER,
            },
          }}
        >
          <Content
            maxWidth={[
              utils.ui.toPx(copyWidth.mobile || ''),
              utils.ui.toPx(copyWidth.desktop || ''),
            ]}
            height={[
              contentHeight?.mobile || '100%',
              contentHeight?.desktop || '100%',
            ]}
            mb={cta.copy ? ['16px', '15px'] : 'auto'}
          >
            {logo && !pinLogoToTop && (
              <Horizontal placement={logoPosition}>
                <Logo image={logo} alt="Logo" />
              </Horizontal>
            )}
            {preHeader && (
              <Text
                className="Text"
                textAlign={[
                  getAlign(copyAlign?.mobile),
                  getAlign(copyAlign?.desktop),
                ]}
              >
                <SanityRichText blocks={preHeader} />
              </Text>
            )}
            {header?.copy && (
              <HeaderWrapper large={header.large}>
                <Header
                  className="Header"
                  color={header.color || theme.colors.basic100}
                  dangerouslySetInnerHTML={{ __html: header.copy }}
                  textAlign={[
                    getAlign(copyAlign?.mobile),
                    getAlign(copyAlign?.desktop),
                  ]}
                />
              </HeaderWrapper>
            )}
            {copy && (
              <Text
                className="Text"
                textAlign={[
                  getAlign(copyAlign?.mobile),
                  getAlign(copyAlign?.desktop),
                ]}
                desktopOnly={mobileDescriptionBottom}
              >
                <SanityRichText blocks={copy} />
              </Text>
            )}
            {emailCollector && (
              <EmailCollector cta={cta} placement={ctaPosition} />
            )}
            {cta && cta.copy && !emailCollector && (
              <Horizontal placement={ctaPosition}>
                <Button
                  className="CTA"
                  href={
                    cta?.linkObject?.page || cta?.linkObject?.url || cta.url
                      ? getUrl(cta.linkObject, cta.url)
                      : getLoginURL()
                  }
                  variant={`${cta?.ctaVariant}` as ButtonVariant}
                  onClick={awaitClick((e) => {
                    const icid = getIcid();
                    return logEvent('Hero Clicked', {
                      key: e.currentTarget.href,
                      ...(icid && { icid }),
                    });
                  })}
                  fullWidthMobile={cta.fullWidthMobile}
                >
                  {cta.copy}
                </Button>
              </Horizontal>
            )}
            {rating && (
              <RatingWrapper>
                <RatingText rating={rating} desktopColumn />
              </RatingWrapper>
            )}
          </Content>
        </Placement>
        {copy && mobileDescriptionBottom && (
          <Text
            className="Text"
            textAlign={[
              getAlign(copyAlign?.mobile),
              getAlign(copyAlign?.desktop),
            ]}
            mobileOnly={mobileDescriptionBottom}
          >
            <SanityRichText blocks={copy} />
          </Text>
        )}
      </Container>
    </SellPageContainer>
  );
};
