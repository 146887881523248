import React from 'react';

import { ViolatorProps, ViolatorAlignment } from './Violator.model';
import { Container } from './Violator.styles';

export const Violator: React.FC<ViolatorProps> = ({
  text,
  textColor,
  bgColor,
  alignment,
}) => {
  return (
    <Container
      className="Violator"
      background={bgColor}
      variant={alignment || ViolatorAlignment.TopLeft}
      color={textColor}
    >
      {text}
    </Container>
  );
};
