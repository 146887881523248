import styled from 'styled-components';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import {
  space,
  flexbox,
  layout,
  typography,
  background,
  BackgroundProps,
  ColorProps,
} from 'styled-utils';

import { Button } from 'components/Common/Button';

export const Wrapper = styled.div<BackgroundProps>`
  ${background};
`;

export const Row = styled.div`
  display: flex;
  ${flexbox({ flexDirection: ['column', 'row'] })};
  ${space({ p: [15, 0], mb: [0, 40] })};
`;

export const ItemSpacer = styled.div`
  ${layout({ width: ['100%', '14px'], height: ['8px', '100%'] })};
`;

export const Item = styled.div<BackgroundProps>`
  flex: 1;
  flex-basis: 420px;
  height: 420px;
  position: relative;
  background: ${({ theme }) => theme.colors.gray};
  ${background};
`;

export const ItemContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Top = styled.div``;

export const SmallImage = styled(GatsbyImage)`
  height: 250px;
`;

export const TopTextWrapper = styled.div`
  ${space({ p: [15, 24] })};
`;

export const TopText = styled.div<ColorProps>`
  color: ${({ color, theme }) => color || theme.colors.basic800};
  max-width: 144px;
  ${typography({ fontSize: [12, 14], lineHeight: ['16px', '20px'] })};
`;
export const TopTextTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.black};
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  ${space({ px: [16, 24] })};
`;
export const Title = styled.h3<ColorProps>`
  ${typography({ fontSize: [20, 26], lineHeight: ['24px', '36px'] })};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  text-align: center;
  margin: 0;
  color: ${({ color, theme }) => color || theme.colors.basic800};
`;
export const Text = styled.div`
  text-align: center;
  p {
    margin-top: 10px;
    ${typography({ fontSize: [14, 18], lineHeight: ['20px', '24px'] })};
  }
`;
export const CTA = styled(Button)`
  height: 42px !important;
  line-height: 42px !important;
  font-size: 14px !important;
`;
