import { StyledButton } from 'components/Common/Button/styles';
import { StandardRichText } from 'components/StandardRichText/StandardRichText';
import styled from 'styled-components';
import { width } from 'styled-utils';

export const Container = styled.div<{
  backgroundColor?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 0;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'initial'};
  ${StyledButton} {
    border-width: 0;
    border-radius: 2px;
    &[disabled] {
      background: ${({ theme }) => theme.colors.basic400};
    }
    width: 250px;
    height: 50px;
  }

  h2 {
    font-size: 22.5px;
  }

  h3 {
    font-size: 20px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const TextContainer = styled.div<{
  desktopWidth?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ desktopWidth }) =>
    desktopWidth
      ? width({ width: ['initial', 'initial', desktopWidth] })
      : width({ width: ['initial', 'initial', '950px'] })};
`;

export const Text = styled(StandardRichText)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`;
