export enum ViolatorAlignment {
  TopLeft = 'Top/Left',
  TopRight = 'Top/Right',
  BottomLeft = 'Bottom/Left',
  BottomRight = 'Bottom/Right',
}

export interface ViolatorProps {
  text: string;
  textColor?: string;
  bgColor?: string;
  alignment?: ViolatorAlignment;
}
