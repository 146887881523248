import styled from 'styled-components';
import {
  color,
  ColorProps,
  space,
  layout,
  variant,
  background,
  BackgroundProps,
} from 'styled-utils';
import { ViolatorAlignment } from './Violator.model';

export const Container = styled.div<
  BackgroundProps & ColorProps & { variant: ViolatorAlignment }
>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.yellow800};
  font-size: 12px;
  ${space({ px: [12, 40] })};
  ${layout({ height: [16, 20] })};
  ${background};
  ${color};
  ${variant({
    variants: {
      [ViolatorAlignment.TopLeft]: {
        top: 16,
        left: 0,
      },
      [ViolatorAlignment.TopRight]: {
        top: 16,
        right: 0,
      },
      [ViolatorAlignment.BottomLeft]: {
        bottom: 16,
        left: 0,
      },
      [ViolatorAlignment.BottomRight]: {
        bottom: 16,
        right: 0,
      },
    },
  })};
`;
