import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import SliderArrow from 'svg/slider-arrow.svg';

export const ArrowSvg = styled.svg``;

export const Arrow: FunctionComponent<{
  direction?: 'right' | 'left';
}> = ({ direction = 'right' }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <ArrowSvg
        width="20"
        height="34"
        viewBox="0 0 20 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="button"
        tabIndex={0}
        style={
          direction === 'left' ? { transform: 'rotate(180deg)' } : undefined
        }
        dangerouslySetInnerHTML={{
          __html: `<use xlink:href="${SliderArrow.url}"></use>`,
        }}
      />
    </div>
  );
};
