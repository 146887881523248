import React, { FunctionComponent } from 'react';
import { SliderDefault, SliderWithProgressAnimation } from './Sliders';
import { SliderProps } from './model';

export const renderSlideShow: FunctionComponent<SliderProps> = (props) => {
  return props.withSlideProgressAnimation ? (
    <SliderWithProgressAnimation {...props} />
  ) : (
    <SliderDefault {...props} />
  );
};

export const Slider: FunctionComponent<SliderProps> = ({
  children,
  handleAfterSlide,
  interval = 3000,
  autoRotate = false,
  withSlideProgressAnimation = false,
  options,
  splitNavigation,
  footNote,
}) => {
  return renderSlideShow({
    children,
    handleAfterSlide,
    interval,
    autoRotate,
    withSlideProgressAnimation,
    options,
    splitNavigation,
    footNote,
  });
};
