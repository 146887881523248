import React from 'react';
import { graphql } from 'gatsby';
import {
  SanityRatingColoredFragment,
  SanitySellPageHeroBlock,
} from 'graphql-types';
import {
  ViolatorAlignment,
  ViolatorProps,
} from 'components/Common/Violator/Violator.model';
import {
  Horizontal,
  mapHorizontal,
  mapVertical,
  Vertical,
} from 'helpers/SanityMapper';
import theme from 'theme';
import Hero from './Hero';
import { HeroContentProps } from './Hero.model';

interface Props {
  fields: SanitySellPageHeroBlock;
  hideContent?: boolean;
}

export const SanityHero = ({ fields, hideContent }: Props) => (
  <Hero
    hideContent={hideContent}
    height={{
      desktop: fields.desktopHeight ?? 500,
      mobile: fields.mobileHeight ?? 500,
    }}
    images={{
      mobile: fields.mobileImage,
      desktop: fields.desktopImage,
    }}
    videos={{
      mobile: fields.mobileVideo?.asset?.url,
      desktop: fields.desktopVideo?.asset?.url,
    }}
    videoTint={fields.videoTint}
    isLockedAspectRatio={fields.isLockedAspectRatio}
    {...mapSanityBlockToHeroContentProps(fields)}
  />
);

export const query = graphql`
  fragment SanitySellPageHeroBlock on SanitySellPageHeroBlock {
    _key
    _type
    __typename
    name
    emailCollector
    violators {
      ...ViolatorFragment
    }
    _rawPreHeader
    header
    largeHeader
    headerColor {
      hex
    }
    ctaLink
    ctaLinkObject {
      ...LinkObject
    }
    ctaVariant
    rating {
      ...SanityRatingColored
    }
    fullWidthMobileCta
    buttonCopy
    mobileHeight
    desktopHeight
    mobileContentHeight
    desktopContentHeight
    mobilePaddingTop
    desktopPaddingTop
    mobilePaddingBottom
    desktopPaddingBottom
    mobileCopyArrangement
    desktopCopyArrangement
    mobileCopyArrangementHorizontal
    desktopCopyArrangementHorizontal
    mobileButtonAlignment
    desktopButtonAlignment
    mobileLogoAlignment
    desktopLogoAlignment
    pinLogoToTop
    desktopContentWidth
    mobileContentWidth
    _rawCopy
    desktopCopyAlignment
    mobileCopyAlignment
    mobileDescriptionBottom
    mobileImage {
      asset {
        gatsbyImageData(width: 768, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    desktopImage {
      asset {
        gatsbyImageData(width: 1170, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    mobileVideo {
      asset {
        url
      }
    }
    desktopVideo {
      asset {
        url
      }
    }
    videoTint
    isLockedAspectRatio
    mobileLogo {
      asset {
        url
      }
    }
    desktopLogo {
      asset {
        url
      }
    }
  }
`;

export const mapSanityBlockToHeroContentProps = (
  fields: SanitySellPageHeroBlock
): HeroContentProps => {
  return {
    emailCollector: fields.emailCollector,
    copyPositionVertical: {
      mobile: mapVertical(fields.mobileCopyArrangement as Vertical),
      desktop: mapVertical(fields.desktopCopyArrangement as Vertical),
    },
    copyPositionHorizontal: {
      mobile: mapHorizontal(
        fields.mobileCopyArrangementHorizontal as Horizontal
      ),
      desktop: mapHorizontal(
        fields.desktopCopyArrangementHorizontal as Horizontal
      ),
    },
    ctaPosition: {
      desktop: mapHorizontal(fields.desktopButtonAlignment as Horizontal),
      mobile: mapHorizontal(fields.mobileButtonAlignment as Horizontal),
    },
    contentHeight: {
      desktop: fields.desktopContentHeight,
      mobile: fields.mobileContentHeight,
    },
    copyWidth: {
      desktop: fields.desktopContentWidth ?? 300,
      mobile: fields.mobileContentWidth ?? 250,
    },
    logo:
      !fields.mobileLogo?.asset && !fields.desktopLogo?.asset
        ? undefined
        : {
            mobile: fields.mobileLogo?.asset?.url,
            desktop: fields.desktopLogo?.asset?.url,
          },
    logoPosition: {
      desktop: mapHorizontal(fields.desktopLogoAlignment as Horizontal),
      mobile: mapHorizontal(fields.mobileLogoAlignment as Horizontal),
    },
    pinLogoToTop: fields?.pinLogoToTop,
    paddingTop: {
      mobile: fields.mobilePaddingTop,
      desktop: fields.desktopPaddingTop,
    },
    paddingBottom: {
      mobile: fields.mobilePaddingBottom,
      desktop: fields.desktopPaddingBottom,
    },
    preHeader: fields?._rawPreHeader,
    header: {
      copy: fields.header,
      color: fields.headerColor?.hex || theme.colors.basic800,
      large: !!fields.largeHeader,
    },
    copy: fields._rawCopy,
    copyAlign: {
      desktop: mapHorizontal(fields.desktopCopyAlignment as Horizontal),
      mobile: mapHorizontal(fields.mobileCopyAlignment as Horizontal),
    },
    mobileDescriptionBottom: fields?.mobileDescriptionBottom,
    cta: {
      copy: fields.buttonCopy,
      url: fields.ctaLink || '#plans', // deprecated
      linkObject: fields.ctaLinkObject || { url: '#plans' },
      fullWidthMobile: fields?.fullWidthMobileCta ?? true,
      ctaVariant: fields?.ctaVariant || 'primary',
    },
    rating: fields.rating as SanityRatingColoredFragment,
    violators:
      fields.violators?.map(
        (violator): ViolatorProps => ({
          text: violator?.violatorText || '',
          textColor: violator?.violatorTextColor?.hex,
          bgColor: violator?.violatorBgColor?.hex,
          alignment: violator?.violatorAlignment as ViolatorAlignment,
        })
      ) || [],
  };
};
