import React from 'react';
import utils from 'utils';

import {
  ResponsiveBackgroundImage,
  HeroBackgroundVideo,
} from 'components/Common/Background';
import { useIsMobile } from 'hooks/use-media-query';

import { HeroProps } from './Hero.model';
import { HeroContent } from './HeroContent';
import { getPaddingTopAspectRatio } from 'components/Image/Image.helper';
import { useIsBrowser } from '@bbnb/openfit-frontend-shared';

const Hero: React.FC<HeroProps> = ({
  images,
  height = {
    desktop: 600,
    mobile: 500,
  },
  contentHeight = {
    mobile: 0,
    desktop: 500,
  },
  emailCollector,
  copyPositionVertical,
  copyPositionHorizontal,
  ctaPosition,
  logo,
  logoPosition,
  pinLogoToTop,
  preHeader,
  header,
  copy,
  copyAlign,
  mobileDescriptionBottom,
  paddingBottom,
  paddingTop,
  copyWidth,
  cta,
  rating,
  violators,
  videos,
  videoTint,
  hideContent,
  isLockedAspectRatio,
}) => {
  const isMobile = useIsMobile();
  const isBrowser = useIsBrowser();
  const content = hideContent ? null : (
    <HeroContent
      {...{
        violators,
        copyPositionVertical,
        copyPositionHorizontal,
        copyAlign,
        mobileDescriptionBottom,
        contentHeight,
        paddingTop,
        paddingBottom,
        logoPosition,
        pinLogoToTop,
        logo,
        copyWidth,
        cta,
        rating,
        copy,
        preHeader,
        header,
        emailCollector,
        ctaPosition,
      }}
    />
  );

  return (
    <>
      {videos?.mobile && isMobile ? (
        <HeroBackgroundVideo
          key={isBrowser.toString()}
          paddingTop={isLockedAspectRatio ? getPaddingTopAspectRatio(9, 16) : 0}
          height={!isLockedAspectRatio ? height?.mobile : undefined}
          poster={images?.mobile?.asset?.gatsbyImageData?.images?.fallback?.src}
          video={videos.mobile}
          videoTint={videoTint}
        >
          {content}
        </HeroBackgroundVideo>
      ) : null}
      {videos?.desktop && !isMobile ? (
        <HeroBackgroundVideo
          key={isBrowser.toString()}
          paddingTop={isLockedAspectRatio ? getPaddingTopAspectRatio(16, 9) : 0}
          height={!isLockedAspectRatio ? height?.desktop : undefined}
          poster={
            images?.desktop?.asset?.gatsbyImageData?.images?.fallback?.src
          }
          video={videos.desktop}
          videoTint={videoTint}
        >
          {content}
        </HeroBackgroundVideo>
      ) : null}

      {!videos?.desktop && !videos?.mobile && (
        <ResponsiveBackgroundImage
          images={images}
          lockAspectRatio={isLockedAspectRatio}
          height={{
            desktop: utils.ui.toPx(height.desktop || 0),
            mobile: utils.ui.toPx(height.mobile || 0),
          }}
        >
          {content}
        </ResponsiveBackgroundImage>
      )}
    </>
  );
};

export default Hero;
