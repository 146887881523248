import React, { FC } from 'react';
import { useLocation } from '@reach/router';
import { SanityButtonFragment } from 'graphql-types';
import { ButtonContainer, StyledButton } from './LadderButton.styles';
import { logEvent, mapAndFormatSearchParams } from 'helpers/Amplitude';

interface Props {
  data: SanityButtonFragment;
}

export const LadderButton: FC<Props> = ({ data: { text, ctaLink } }) => {
  const { search } = useLocation();

  const handleCtaClick = (e: MouseEvent) => {
    const params = mapAndFormatSearchParams(new URLSearchParams(search));

    return logEvent('CTA Click', {
      ...((params.get('icid') && { icid: params.get('icid') }) || {}),
    });
  };

  return (
    <ButtonContainer>
      <StyledButton onClick={handleCtaClick} href={ctaLink}>
        {text}
      </StyledButton>
    </ButtonContainer>
  );
};
