import React, { FunctionComponent, useRef } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import {
  SliderContainer,
  SplitSliderDotContainer,
  InlineDot,
  DotsContainer,
  Dot,
  ArrowRight,
  ArrowLeft,
  FootNote,
} from '../Slider.styles';
import { Arrow } from './Arrow';
import { SliderProps } from '../model';
import { getNextInlineDotsLocation } from './helper';
import { SliderContextProvider } from 'Context/Slider/Slider.context';
import { StandardRichText } from 'components/StandardRichText/StandardRichText';

export const SliderDefault: FunctionComponent<SliderProps> = ({
  children,
  handleAfterSlide,
  interval,
  autoRotate,
  options,
  splitNavigation,
  footNote,
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };
  const handleDragEnd = () => {
    setIsDragging(false);
  };
  // Doc: https://keen-slider.io/api/#api
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    afterChange: handleAfterSlide,
    dragStart: handleDragStart,
    dragEnd: handleDragEnd,
    loop: true,
    ...options,
  });

  const [textLocation, setTextLocation] = React.useState('' as string);

  React.useEffect(() => {
    setTextLocation(getNextInlineDotsLocation(children[0]));
  }, []);

  // #region AutoPlay
  const timerRef = useRef<undefined | ReturnType<typeof Number>>();
  React.useEffect(() => {
    if (autoRotate) {
      timerRef.current = window.setInterval(() => {
        if (slider) {
          slider.next();
        }
      }, interval);
      return () => {
        clearInterval(timerRef.current);
      };
    }
    return undefined;
  }, [slider]);
  // #endregion

  return (
    <SliderContextProvider
      slider={slider}
      currentSlide={currentSlide}
      isDragging={isDragging}
    >
      <SliderContainer>
        <>
          <div
            className="keen-slider"
            ref={ref}
            style={{ visibility: slider ? 'visible' : 'hidden' }}
          >
            {children.map((slide, idx) => (
              <div key={idx} className="keen-slider__slide">
                {slide}
              </div>
            ))}
          </div>
          {slider && !splitNavigation && (
            <>
              <ArrowLeft onClick={slider.prev}>
                <Arrow direction="left" />
              </ArrowLeft>
              <ArrowRight onClick={slider.next}>
                <Arrow />
              </ArrowRight>
              <DotsContainer>
                {[...Array(slider.details().size).keys()].map(
                  (slideIndex): React.ReactElement => (
                    <Dot
                      data-test-id="dot"
                      key={slideIndex}
                      className={slideIndex === currentSlide ? 'active' : ''}
                      onClick={(): void => {
                        slider.moveToSlide(slideIndex);
                      }}
                      isActive={slideIndex === currentSlide}
                    />
                  )
                )}
                {footNote && (
                  <FootNote>
                    <StandardRichText fields={footNote} />
                  </FootNote>
                )}
              </DotsContainer>
            </>
          )}
          {slider && splitNavigation && (
            <SplitSliderDotContainer textLocation={textLocation}>
              {[...Array(slider.details().size).keys()].map(
                (slideIndex): React.ReactElement => {
                  return (
                    <InlineDot
                      data-test-id="dot"
                      key={slideIndex}
                      onClick={(): void => {
                        setTextLocation(
                          getNextInlineDotsLocation(children[slideIndex])
                        );
                        slider.moveToSlide(slideIndex);
                      }}
                      isActive={slideIndex === currentSlide}
                    ></InlineDot>
                  );
                }
              )}
            </SplitSliderDotContainer>
          )}
        </>
      </SliderContainer>
    </SliderContextProvider>
  );
};
