import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  CollectionSliderProps,
  CollectionItemProps,
} from './CollectionSlider.model';
import {
  Wrapper,
  Container,
  CollectionItemText,
  CollectionItemImage,
  CollectionItemWrapper,
  CollectionItemContainer,
} from './CollectionSlider.styles';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { getUrl } from 'helpers/LinkObject';

const CollectionItem: React.FC<CollectionItemProps> = ({
  image,
  title,
  url, // deprecated
  linkObject,
  cardBackgroundColor,
  cardTextColor,
}) => {
  const itemImage = getSimplifiedImageData(image);
  if (!itemImage) {
    return null;
  }

  return (
    <CollectionItemWrapper>
      <CollectionItemContainer
        href={getUrl(linkObject, url)}
        backgroundColor={cardBackgroundColor}
      >
        <CollectionItemImage image={itemImage} alt={title} />
        <CollectionItemText color={cardTextColor}>{title}</CollectionItemText>
      </CollectionItemContainer>
    </CollectionItemWrapper>
  );
};

export const CollectionSlider: React.FC<CollectionSliderProps> = ({
  backgroundColor,
  cardBackgroundColor,
  cardTextColor,
  items,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Container backgroundColor={backgroundColor ?? theme.colors.basic200}>
      <Wrapper>
        {items.map((item) => (
          <CollectionItem
            key={item.title}
            cardBackgroundColor={cardBackgroundColor ?? theme.colors.basic100}
            cardTextColor={cardTextColor ?? theme.colors.black}
            {...item}
          />
        ))}
      </Wrapper>
    </Container>
  );
};
