import styled from 'styled-components';

export const StaticContent = styled.div`
  position: relative;
  display: flex;
`;

export const StaticBody = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`;

export const StaticSlider = styled.div`
  width: 100%;
  height: 100%;
`;
