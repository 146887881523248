import KeenSlider from 'keen-slider';
import React, { createContext, useContext } from 'react';

export type SliderContextState = {
  slider?: KeenSlider;
  currentSlide: number;
  isDragging: boolean;
};

export const SliderContext = createContext<SliderContextState>(
  {} as SliderContextState
);

export const useSliderContext = () => useContext(SliderContext);

export const SliderContextProvider: React.FC<SliderContextState> = ({
  slider,
  currentSlide,
  isDragging,
  children,
}) => {
  return (
    <SliderContext.Provider value={{ slider, currentSlide, isDragging }}>
      {children}
    </SliderContext.Provider>
  );
};
