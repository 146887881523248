import React from 'react';
import styled, { keyframes } from 'styled-components';
import { space, layout, top } from 'styled-system';
import { display } from 'styled-utils';
import { device } from 'theme';
import pauseButtonIcon from 'svg/pause-button.svg';
import playButtonIcon from 'svg/play-button.svg';
import { Svg } from '../Svg';
import { ArrowSvg } from './Sliders/Arrow';

export const PauseButtonContainer = styled.div`
  height: 100%;
  right: 90px;
  top: 16px;
  border: none;
  cursor: pointer;
  button {
    position: absolute;
    width: 32px;
    height: 32px;
    bottom: 20px;
    left: 20px;
    margin-left: 20px;
    z-index: 1;
    padding: 0;
    border: none;
    background: none;
    border-radius: 0;
    cursor: pointer;
    svg {
      border: none;
      background: none;
      border-radius: 0;
    }
    &:hover {
      border: none;
      svg {
        border: none;
        opacity: 0.5;
      }
    }
  }
`;

export const PauseButton = () => {
  return <Svg icon={pauseButtonIcon} />;
};

export const PlayButton = () => {
  return <Svg icon={playButtonIcon} />;
};

export const FootNote = styled.p`
  bottom: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
`;
export const SliderContainer = styled.div`
  position: relative;
  .keen-slider {
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;

    &__slide {
      position: relative;
      overflow: hidden;
      width: 100%;
      min-height: 100%;
    }

    &[data-keen-slider-v] {
      flex-wrap: wrap;
    }

    &[data-keen-slider-v] &__slide {
      width: 100%;
    }

    &[data-keen-slider-moves] * {
      pointer-events: none;
    }
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  ${top({ top: ['-38px', '-55px'] })};
`;

export const Dot = styled.button<{ isActive?: boolean }>`
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  appearance: none;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: 0;
  }
  background: ${({ isActive, theme }): string =>
    isActive ? theme.colors.red400 : theme.colors.basic400};
  border-radius: 50%;
  ${layout({ size: ['8px'] })};
  ${space({ mx: ['4px'] })};
`;

const Arrow = styled.div`
  ${display({ display: ['none', 'block'] })};
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
`;

export const AnimatedDotContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0 3rem;
`;

export const InlineDot = styled.button<{ isActive?: boolean }>`
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: 0;
  }
  background: ${({ isActive, theme }): string =>
    isActive ? theme.colors.blue400 : theme.colors.basic400};
  border-radius: 50%;
  ${layout({ size: ['8px'] })};
  ${space({ mx: ['4px'] })};
`;

export const SplitSliderDotContainer = styled.div<{ textLocation?: string }>`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: ${({ textLocation }): string =>
    textLocation === 'Right' ? '-180px' : '790px'};
  margin: 0 3rem;
  @media (max-width: ${device.small.width}) {
    display: none;
  }
  z-index: 1;
`;

export const SplitSliderInlineDotContainer = styled.div<{ isActive: boolean }>`
  display: none;
  z-index: 1;
  transition: opacity 0.25s ease-in-out;
  @media (max-width: ${device.small.width}) {
    position: relative;
    display: flex;
    opacity: ${({ isActive }): string => (isActive ? '1' : '0')};
    flex-flow: row;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 20px 0 10px 0;
  }
`;

export const Button = styled.button<{ isActive: boolean }>`
  position: relative;
  width: 84px;
  height: 34px;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  text-align: left;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 16px 4px;
`;

const ProgressAnimation = keyframes`
 0% { width: 0; }
 100% { width: 100%; }
`;

const PausedAnimation = keyframes`
 0% { width: 0; }
 100% { width: 0; }
`;

interface IntervalTimeProps {
  interval?: number;
  pause?: boolean;
}

export const AnimatedButton = styled.div<IntervalTimeProps>`
  height: 2px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 5px;
  background-color: rgba(255, 255, 255, 0.25);
  display: block;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    animation-name: ${(props) =>
      props.pause ? PausedAnimation : ProgressAnimation};
    animation-duration: ${(props): string =>
      props.interval ? String(props.interval) : String(0)}ms;
    animation-iteration-count: 1;
    animation-play-state: ${(props): string =>
      props.pause ? `paused` : 'running'};
    z-index: 3;
    animation-timing-function: linear;
  }
`;

export const NonAnimatedButtonDone = styled.div<IntervalTimeProps>`
  height: 2px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px 5px;
  background-color: rgb(255, 255, 255);
  display: block;
  position: relative;
`;

export const NonAnimatedButton = styled.div<IntervalTimeProps>`
  height: 2px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 5px;
  background-color: rgba(255, 255, 255, 0.25);
  display: block;
  position: relative;
`;

export const ArrowRight = styled(Arrow)`
  left: auto;
  right: 45px;
`;

export const ArrowLeft = styled(Arrow)`
  left: 45px;
`;

export const CircleArrows = styled.div`
  ${space({ px: [0, 0, '45px'] })};

  ${DotsContainer} {
    top: -30px;
  }

  ${Dot} {
    &.active {
      background: ${({ theme }) => theme.colors.blue400};
    }
  }

  ${Arrow} {
    ${display({ display: ['none', 'none', 'block'] })};
    top: 42%;
    width: 56px;
    height: 56px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 2px 17px rgba(0, 0, 0, 0.2);

    ${ArrowSvg} {
      display: none;
    }
  }

  ${Arrow}::before {
    content: '';
    position: absolute;
    top: 22px;
    width: 13px;
    height: 13px;
    border: solid #2b2738;
    border-left: 2px;
    border-top: 2px;
  }

  ${ArrowLeft} {
    left: -35px;

    &:before {
      right: 19px;
      transform: rotate(135deg);
    }
  }

  ${ArrowRight} {
    right: -35px;

    &:before {
      left: 19px;
      transform: rotate(-45deg);
    }
  }
`;
