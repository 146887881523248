import React, { FC } from 'react';
import {
  SanityButtonFragment,
  SanityRichTextWithBackgroundFragment,
  StandardRichTextFragment,
} from 'graphql-types';
import {
  Container,
  TextContainer,
  Text,
} from './RichTextWithBackground.styles';
import { LadderButton } from 'components/LadderButton/LadderButton';
import { BlockProps } from 'components/Common/SanityRichText/';

interface Props {
  data: SanityRichTextWithBackgroundFragment;
}

export const serializerTypes = {
  button: (props: BlockProps<SanityButtonFragment>) => {
    return <LadderButton data={props.node} />;
  },
};

export const RichTextWithBackground: FC<Props> = ({
  data: { text, desktopWidth, backgroundColor },
}) => {
  return (
    <Container backgroundColor={backgroundColor?.hex}>
      <TextContainer desktopWidth={desktopWidth}>
        <Text
          fields={text as StandardRichTextFragment}
          serializerTypes={serializerTypes}
        />
      </TextContainer>
    </Container>
  );
};
